@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: #333;
}

.admin-container-content p img {
  display: block;
  max-width: 100%;
  /* max-height: 100%; */
  width: auto;
  height: auto;
}

.admin-container-content h1,
.admin-container-content h2,
.admin-container-content h3,
.admin-container-content h4,
.admin-container-content h5,
.admin-container-content h6 {
  font-weight: 700;
  letter-spacing: 1px;
  color: #0066cc;
}

/* Paragraphs and text */
.admin-container-content p {
  font-size: 1rem;
}

/* Links */
.admin-container-content a {
  color: #0066cc;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.admin-container-content a:hover {
  color: #00509e;
}

/* Lists */
.admin-container-content ul,
.admin-container-content ol {
  margin-bottom: 0.8em;
  padding-left: 1.5em;
}
.admin-container-content li::marker {
  color: #0066cc;
}

/* Tables */
.admin-container-content table {
  width: 100%;
  border-collapse: collapse;
}

.admin-container-content th,
.admin-container-content td {
  padding: 0.5em;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

/* Subtle hover effect for list items */
li:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

@media (max-width: 768px) {
  body {
    font-size: 0.9rem; /* Slightly smaller font for mobile */
  }

  .admin-container-content h1 {
    font-size: 1.8rem; /* Adjust heading sizes for mobile */
  }

  .admin-container-content h2 {
    font-size: 1.6rem;
  }

  .admin-container-content h3 {
    font-size: 1.4rem;
  }

  .admin-container-content p,
  .admin-container-content li,
  .admin-container-content a {
    font-size: 0.9rem; /* Slightly smaller text for mobile readability */
  }

  /* Adjust table styling for better mobile viewing */
  .admin-container-content th,
  .admin-container-content td {
    font-size: 0.9rem;
    padding: 0.3em;
  }

  /* Increase the padding-left for lists to keep them more legible on small screens */
  .admin-container-content ul,
  .admin-container-content ol {
    padding-left: 1.2em;
  }
}
