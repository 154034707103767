body {
  font-family: Nunito, arial, verdana;
}

.admin-container {
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: 65px 1fr;
  grid-template-areas:
    "navbar navbar"
    "sidebar page";
}

.admin-container-navbar {
  grid-area: navbar;
}

.admin-container-sidebar {
  grid-area: sidebar;
}

.admin-container-page {
  grid-area: page;
}

/* scrollbar start */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgb(255, 255, 255);  */
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1976d2;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #005dd6;
}
/* scrollbar end */
