@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap);
.menu-add-btn:hover {
  /* color:red;  */
  /* font-weight: bold; */
  /* background-color:gray; */
  cursor: pointer;
  animation: scale-up-center 0.1s cubic-bezier(0, 0, 1, 1) both;
  /* border: 10px  solid white; */
}
/* .menu-add-btn{
  color: #ddd;
} */

@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}

* {
  margin: 0;
  padding: 0;
}
/* Scrollbar-effect------- */
.accordian::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.accordian::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e4e4e4;
}
.accordian::-webkit-scrollbar-thumb {
  background: #0089ff;
  border-radius: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.accordian::-webkit-scrollbar-thumb:hover {
  background: #d5b14c;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* --------- */

.accordian {
  background: #fff;
  width: calc(100% - 20px);
  padding: 10px;
  min-height: calc(100vh - 85px);
  -webkit-user-select: none;
          user-select: none;
}

.accordian a {
}

.accordian i {
  margin-right: 10px;
}

.accordian li {
  list-style-type: none;
}

.accordian ul li a {
  color: #333;
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 12px 15px;
  position: relative;
  border-radius: 3px;
  /* box-shadow: 0px 0px 1px rgb(255, 255, 255); */
  opacity: 1;
}

@keyframes anima {
  0% {
    transform: scale(0.5);
    transform-origin: 0% 0%;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 0%;
  }
}

.accordian > ul.show-dropdown > li.active > a,
.accordian > ul > li > ul.show-dropdown > li.active > a,
.accordian > ul > li > ul > li > ul.show-dropdown > li.active > a,
.accordian > ul > li > ul > li > ul > li > ul.show-dropdown > li.active > a,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul.show-dropdown
  > li.active
  > a,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul.show-dropdown
  > li.active
  > a {
  background-color: #2b86e0;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 137, 255, 0.2);
  /* border: 1px #1976d2 solid; */
}

.accordian > ul.show-dropdown > li > a:hover,
.accordian > ul > li > ul.show-dropdown > li > a:hover,
.accordian > ul > li > ul > li > ul.show-dropdown > li > a:hover,
.accordian > ul > li > ul > li > ul > li > ul.show-dropdown > li > a:hover,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul.show-dropdown
  > li
  > a:hover,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul.show-dropdown
  > li
  > a:hover {
  background-color: #0056b3;
  color: white;
  animation: animaHover 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@keyframes animaHover {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.9;
  }
}

.accordian > ul > li > ul,
.accordian > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul > li > ul > li > ul {
  display: none;
}

.accordian > ul > li.active > ul.show-dropdown,
.accordian > ul > li > ul > li.active > ul.show-dropdown,
.accordian > ul > li > ul > li > ul > li.active > ul.show-dropdown,
.accordian > ul > li > ul > li > ul > li > ul > li.active > ul.show-dropdown,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li.active
  > ul.show-dropdown {
  display: block;
}

.accordian > ul > li > ul,
.accordian > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul > li > ul > li > ul {
  padding-left: 20px;
  animation: anima 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  margin-top: 2px;
  margin-bottom: 2px;
}

.container-sidebar {
  margin: 8px;
}

.input-label {
  color: #8597a3;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.input-sidebar {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  width: 100%;
  outline: none;
}
.input-sidebar:focus,
.input-sidebar:valid {
  outline: 0;
  border-bottom-color: #9c27b0;
}

.btn-sidebar {
  cursor: pointer;
  padding: 4px;
  background-color: #9c27b0;
  border-radius: 6px;
  color: #fff;
  border: 0;
  margin: 8px 0;
}
.btn-sidebar:focus {
  outline: 0;
}

body {
  font-family: Nunito, arial, verdana;
}

.admin-container {
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: 65px 1fr;
  grid-template-areas:
    "navbar navbar"
    "sidebar page";
}

.admin-container-navbar {
  grid-area: navbar;
}

.admin-container-sidebar {
  grid-area: sidebar;
}

.admin-container-page {
  grid-area: page;
}

/* scrollbar start */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgb(255, 255, 255);  */
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1976d2;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #005dd6;
}
/* scrollbar end */

.loading-body {
  -webkit-backdrop-filter: blur(0.5px);
          backdrop-filter: blur(0.5px);
  grid-row-start: 0;
  grid-row-end: 1;
  grid-column-start: 0;
  grid-column-end: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
}
.spinner {
  width: 10em;
  height: 10em;
  border-top: 1em solid #6da9f5;
  border-right: 1em solid transparent;
  border-radius: 50%;
  margin: auto;
  animation: spinner 0.3s linear infinite;
}
.head {
  width: 1em;
  height: 1em;
  background-color: #6da9f5;
  border-radius: 50%;
  margin-left: 8.5em;
  margin-top: 0.5em;
}
@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

/* 
 USAGE:

 ==============================================

  CSS:

  <Loading/> parent class:
  .post-page-container {
    display: grid;
  }

  class after <Loading/>
  .post-page-container-texts{
    grid-row-start: 0 ;
    grid-row-end: 1;
    grid-column-start: 0;
    grid-column-end: 1;
  }

  ==============================================

  HTML:
  <div class="post-page-container">
    {loading ? <Loading /> : null}
      <div class="post-page-container-texts">
        hello world
    </div
  </div

  ==============================================

  React:
  const [loading, setLoading] = useState(false);
  const getData = ()=>{
    setLoading(true);
    get("/posts/" + params.id.toString()).then((data) => {
      data = data.data.data;
      setPosts(data);
      setLoading(false);
    });
  }
*/

.post-page-container {
  display: grid;
}

.post-page-container-texts {
  grid-row-start: 0;
  grid-row-end: 1;
  grid-column-start: 0;
  grid-column-end: 1;
}

.link-button:link,
.link-button:visited {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: #333;
}

.admin-container-content p img {
  display: block;
  max-width: 100%;
  /* max-height: 100%; */
  width: auto;
  height: auto;
}

.admin-container-content h1,
.admin-container-content h2,
.admin-container-content h3,
.admin-container-content h4,
.admin-container-content h5,
.admin-container-content h6 {
  font-weight: 700;
  letter-spacing: 1px;
  color: #0066cc;
}

/* Paragraphs and text */
.admin-container-content p {
  font-size: 1rem;
}

/* Links */
.admin-container-content a {
  color: #0066cc;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.admin-container-content a:hover {
  color: #00509e;
}

/* Lists */
.admin-container-content ul,
.admin-container-content ol {
  margin-bottom: 0.8em;
  padding-left: 1.5em;
}
.admin-container-content li::marker {
  color: #0066cc;
}

/* Tables */
.admin-container-content table {
  width: 100%;
  border-collapse: collapse;
}

.admin-container-content th,
.admin-container-content td {
  padding: 0.5em;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

/* Subtle hover effect for list items */
li:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

@media (max-width: 768px) {
  body {
    font-size: 0.9rem; /* Slightly smaller font for mobile */
  }

  .admin-container-content h1 {
    font-size: 1.8rem; /* Adjust heading sizes for mobile */
  }

  .admin-container-content h2 {
    font-size: 1.6rem;
  }

  .admin-container-content h3 {
    font-size: 1.4rem;
  }

  .admin-container-content p,
  .admin-container-content li,
  .admin-container-content a {
    font-size: 0.9rem; /* Slightly smaller text for mobile readability */
  }

  /* Adjust table styling for better mobile viewing */
  .admin-container-content th,
  .admin-container-content td {
    font-size: 0.9rem;
    padding: 0.3em;
  }

  /* Increase the padding-left for lists to keep them more legible on small screens */
  .admin-container-content ul,
  .admin-container-content ol {
    padding-left: 1.2em;
  }
}

