* {
  margin: 0;
  padding: 0;
}
/* Scrollbar-effect------- */
.accordian::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.accordian::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e4e4e4;
}
.accordian::-webkit-scrollbar-thumb {
  background: #0089ff;
  border-radius: 10px;
  transition: 0.5s;
}
.accordian::-webkit-scrollbar-thumb:hover {
  background: #d5b14c;
  transition: 0.5s;
}

/* --------- */

.accordian {
  background: #fff;
  width: calc(100% - 20px);
  padding: 10px;
  min-height: calc(100vh - 85px);
  user-select: none;
}

.accordian a {
}

.accordian i {
  margin-right: 10px;
}

.accordian li {
  list-style-type: none;
}

.accordian ul li a {
  color: #333;
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 12px 15px;
  position: relative;
  border-radius: 3px;
  /* box-shadow: 0px 0px 1px rgb(255, 255, 255); */
  opacity: 1;
}

@keyframes anima {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

.accordian > ul.show-dropdown > li.active > a,
.accordian > ul > li > ul.show-dropdown > li.active > a,
.accordian > ul > li > ul > li > ul.show-dropdown > li.active > a,
.accordian > ul > li > ul > li > ul > li > ul.show-dropdown > li.active > a,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul.show-dropdown
  > li.active
  > a,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul.show-dropdown
  > li.active
  > a {
  background-color: #2b86e0;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 137, 255, 0.2);
  /* border: 1px #1976d2 solid; */
}

.accordian > ul.show-dropdown > li > a:hover,
.accordian > ul > li > ul.show-dropdown > li > a:hover,
.accordian > ul > li > ul > li > ul.show-dropdown > li > a:hover,
.accordian > ul > li > ul > li > ul > li > ul.show-dropdown > li > a:hover,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul.show-dropdown
  > li
  > a:hover,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul.show-dropdown
  > li
  > a:hover {
  background-color: #0056b3;
  color: white;
  animation: animaHover 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@keyframes animaHover {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.9;
  }
}

.accordian > ul > li > ul,
.accordian > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul > li > ul > li > ul {
  display: none;
}

.accordian > ul > li.active > ul.show-dropdown,
.accordian > ul > li > ul > li.active > ul.show-dropdown,
.accordian > ul > li > ul > li > ul > li.active > ul.show-dropdown,
.accordian > ul > li > ul > li > ul > li > ul > li.active > ul.show-dropdown,
.accordian
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li.active
  > ul.show-dropdown {
  display: block;
}

.accordian > ul > li > ul,
.accordian > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul > li > ul,
.accordian > ul > li > ul > li > ul > li > ul > li > ul > li > ul {
  padding-left: 20px;
  animation: anima 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  margin-top: 2px;
  margin-bottom: 2px;
}

.container-sidebar {
  margin: 8px;
}

.input-label {
  color: #8597a3;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.input-sidebar {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  width: 100%;
  outline: none;
}
.input-sidebar:focus,
.input-sidebar:valid {
  outline: 0;
  border-bottom-color: #9c27b0;
}

.btn-sidebar {
  cursor: pointer;
  padding: 4px;
  background-color: #9c27b0;
  border-radius: 6px;
  color: #fff;
  border: 0;
  margin: 8px 0;
}
.btn-sidebar:focus {
  outline: 0;
}
